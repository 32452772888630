<template>
    <v-container mt-12 pa-3 id="skills">
        <v-row>
            <v-col cols="12">
                <h2 class="font-weight-black text-center text-decoration-underline">Skills</h2>
                <br>
                <v-card tile class="mx-auto info" info>
                    <v-list flat three-line class="white rounded">
                        <v-list-item v-for="skill in skillSet" :key="skill.name">
                            <v-list-item-content>
                                <v-list-item-title class="text--black">
                                    <v-icon color="primary">mdi-{{ skill.icon }}</v-icon>
                                    {{ skill.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text--black">{{ skill.description }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<style>
</style>

<script>
export default {
    name: "SkillsPage",

    data: () => ({
        skillSet: [
            {
                name: "JavaScript",
                icon: "language-javascript",
                description: "Full Stack experience with a range of vanilla and framework-based projects."
            },
            {
                name: "VueJS",
                icon: "vuejs",
                description: "Recent primary development framework for front-end projects."
            },
            {
                name: "NodeJS",
                icon: "nodejs",
                description: "Back end experience including authorization, database creation and implementation, image and file processing, and complex API integrations."
            },
            {
                name: "Excel VBA",
                icon: "file-excel-box",
                description: "Extensive experience across research data, commerce, demographic, and other datasets creating macros to reliably automate data processing."
            },
            {
                name: "Data Analysis in Academic Research",
                icon: "school",
                description: "Statistical analysis with cognitive and behavioral neuroscience and neuropsychology labs, including database development and maintainance."

            },
            {
                name: "Transportation Logistics",
                icon: "van-passenger",
                description: "Logistics manager experienced building routes maximizing efficiciency and profitability."
            }
        ],

    }),
};
</script>
