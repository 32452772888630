import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueTypedJs from 'vue-typed-js'
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.config.productionTip = false
Vue.use(VueTypedJs) // vue-typed-js
Vue.use(VueSmoothScroll, {
  duration: 250,
  updateHistory: false
}) // vue2-smooth-scroll

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
